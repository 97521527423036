<template lang="pug">
.brand-wrapper.brand-display-rules
  .container-fluid
    .row
      .col-12
        .font-size-0--75.text-color-mild-gray {{ $t('editCampaignName') }}
        editable-title.mb-5(
          h1
          v-if="campaign.name"
          :title="campaign.name"
          @renameTitle="renameCampaign"
        )

      //- .brand-link.ml-auto(@click="$router.go(-1)") {{ $t('back') }}
    campaign-settings-box(
      :show="activeBox === 'trigger'"
      :bottomBorder="activeBox !== 'condition'"
      :hasError="events && events.length === 0"
      :campaign="campaign"
      v-if="!isEmbedded && !isDynamicContent"
      @activate="activeBox = 'trigger'"
      @close="activeBox = 'condition'"
    )
      template(slot="title") {{ $t('whenToShowLong') }}
      template(slot="content")
        .pt-3
          transition-group.overflow-hidden(name="fade" tag="div")
            template(v-for="(event, index) in events")
              .campaign-setting-card.card.cursor-pointer(
                @click="showEventsModal(event.type)"
                :key="event.type"
              )
                .card-body.card-body-line.px-0
                  event-box(:event="event")
                    template(slot="action")
                      .d-flex.justify-content-end.flex-grow-1
                        .remove-setting-box(@click.stop="removeRule('events', index)")
                          uil-times.cursor-pointer.remove-setting(:size="'25px'")
                .rule-logical-condition.rule-logical-condition-or(
                  v-if="index < events.length - 1"
                  :class="`rule-logical-condition-or-${$i18n.locale}`"
                ) {{ $t('or') }}
            .campaign-setting-card.card.last-child.campaign-setting-card-skeleton(
              key="add-rule-row"
            )
              .card-body.px-0.card-body-line
                .d-flex
                  om-button.text-primary(
                    secondary
                    large
                    icon="plus-circle"
                    @click="$modal.show('new-events-modal')"
                  ) {{ $t('addMoreTrigger') }}
                  slot(name="add-rule-section")
      template(slot="button") {{ $t('triggersFinish') }}
    campaign-settings-box(
      :show="activeBox === 'embedding'"
      :bottomBorder="activeBox !== 'condition'"
      :hasWarning="!hasEmbeddedPositions"
      :campaign="campaign"
      v-if="isEmbedded"
      :outlinedButton="true"
      @activate="activeBox = 'embedding'"
      @close="activeBox = 'condition'"
    )
      template(slot="title") {{ $t('whereToPlaceEmbeddedContent') }}
      template(slot="content")
        .pt-4
          .embed-rule-box
            .row.w-100.mx-0
              .col-9.d-flex.align-items-center.px-0
                trigger-svg.svg-preview.flex-shrink-0(type="click")
                .brand-rule-box-content(v-if="!hasEmbeddedPositions")
                  .d-flex.align-items-center
                    .brand-rule-box-title {{ $t(`settingsSummary.pointClickTitle`) }}
                    .brand-badge.brand-badge-secondary.ml-3 {{ $t('recommended') }}
                  .brand-rule-box-lead.mt-2 {{ $t(`usePointAndClick`) }}
                .brand-rule-box-content(v-else)
                  template(v-if="isManuallyEmbedded")
                    .brand-rule-box-title {{ $t(`usedManualEmbed`) }}
                    .brand-rule-box-lead.mt-2 {{ $t(`confirmedManuallyEmbedded`) }}
                    .brand-rule-box-lead.mt-1.brand-link(@click="$modal.show('embed-code')") {{ $t(`showManuallyEmbeddedCode`) }}
                  template(v-else-if="isEmbeddedV3 && !isManuallyEmbedded")
                    .brand-rule-box-title {{ $t(`usedPointAndClick`) }}
                    .brand-rule-box-lead.mt-2 {{ $t(`settingsSummary.pointAndClick.whereIsItInserted`, { position: $t(`settingsSummary.pointAndClick.${campaign.positions[0].position}`), selector: campaign.positions[0].selector }) }}
                    .brand-rule-box-lead.mt-1 {{ $t(`settingsSummary.pointAndClick.embeddedUrlInfo`) }}
                  template(v-else)
                    .brand-rule-box-title {{ $t(`usedPointAndClick`) }}
                    .brand-rule-box-lead.mt-2 {{ campaign.domain + campaign.positions[0].path }}
                    .brand-rule-box-lead.mt-1 {{ $t(`settingsSummary.pointAndClick.whereIsItInserted`, { position: $t(`settingsSummary.pointAndClick.${campaign.positions[0].position}`), selector: campaign.positions[0].selector }) }}
              .col-3.d-flex.align-items-center.justify-content-end.pr-0
                button.brand-btn.brand-btn-primary(@click="openWebSelector") {{ $t(`placeContent`) }}
          .embed-manually-label(
            v-if="!isManuallyEmbedded"
            @click="$modal.show('embed-code')"
            v-html="$t(`alternativelyEmbedManually`)"
          )
      template(slot="button") {{ $t('okItsDone') }}
    om-modal.brand-embedded-modal(
      v-if="this.isEmbedded"
      name="embed-code"
      width="750"
      color="light"
    )
      template(slot="modal-header")
        .w-100.text-left.font-weight-bold.font-size-1--25 {{ $t('embedCode.title') }}
      template(slot="modal-body")
        .mt-1.mb-2.text-left {{ $t('embedCode.desc') }}
        highlight.embed-code-highlight(
          :codeStr="$t('embedCode.code', { campaign: this.$route.params.id })"
        )
      template(slot="modal-footer")
        .d-flex.justify-content-end
          om-button(ghost @click="$modal.hide('embed-code')") {{ $t('cancel') }}
          om-button.ml-3(primary @click="confirmManuallyEmbedded") {{ $t('okItsDone') }}
    Targeting(
      :show="activeBox === 'condition'"
      :bottomBorder="activeBox !== 'integration'"
      :hasWarning="frontendRules.length === 0"
      :campaign="campaign"
      @activate="activeBox = 'condition'"
      @close="onTargetingClose"
      @change="campaign.frontendRules = $event"
    )
      template(#title-content)
        .d-flex.justify-content-between
          div {{ targetingTitle }}
          transition(name="fade")
            .d-flex(v-if="activeBox === 'condition'")
              om-button(secondary @click="openSegmentChooseModal(false)") {{ $t('userSegment.buttons.load') }}
      template(#add-rule-section)
        transition(name="fade")
          om-button.text-primary.ml-3(
            :disabled="!campaign.frontendRules.length"
            secondary
            large
            @click="$modal.show('segments-user-save-modal', { frontendRules: campaign.frontendRules })"
          ) {{ $t('userSegment.buttons.save') }}
      template(#settings-button) {{ settingsButtonText }}
    campaign-settings-box(
      v-if="!isDynamicContent"
      :show="activeBox === 'integration'"
      :bottomBorder="true"
      :hasWarning="campaign.integrations.length === 0"
      :campaign="campaign"
      @activate="activeBox = 'integration'"
      @close="onIntegrationClose"
    )
      template(slot="warning")
        .text-color-mild-gray.font-size-0--9375 {{ $t('integrationWarning') }}
      template(slot="title") {{ $t('integration') }}
      template(slot="content")
        .pt-3.pb-4
          template(v-if="isNewIntegrationFlow")
            transition-group(name="fade" tag="div")
              analytics-box(key="analytics-box" :campaign="campaign")
              template(
                v-if="campaign.integrations.length"
                v-for="(integration, index) in campaign.integrations"
              )
                template(v-if="isIntegrationNewFlowAvailable(integration.global.type)")
                  .campaign-integration-card.new-flow.cursor-pointer(
                    @click="showIntegrationNewFlowModal(integration)"
                    :key="integration._id"
                  )
                    integration-box(:integration="integration")
                      template(slot="action")
                        i.fas.fa-edit(@click="showIntegrationNewFlowModal(integration)")
                        i.fas.fa-times(
                          v-if="showIntegrationDeleteIcon(integration)"
                          @click.stop="removeRule('integrations', index)"
                        )
                template(v-else)
                  .campaign-integration-card.new-flow.cursor-pointer(
                    @click="$modal.show('new-campaign-integration', { integrationId: integration._id })"
                    :key="integration._id"
                  )
                    integration-box(:integration="integration")
                      template(slot="action")
                        i.fas.fa-edit(
                          @click="$modal.show('new-campaign-integration', { integrationId: integration._id })"
                        )
                        i.fas.fa-times(
                          v-if="showIntegrationDeleteIcon(integration)"
                          @click.stop="removeRule('integrations', index)"
                        )
            template(v-for="(recommendationIntegration, index) in recommendedIntegrations")
              recommendation-box(
                v-if="noAddedIntegrationType(recommendationIntegration)"
                :type="recommendationIntegration"
                :index="index"
                :campaignId="campaignId"
                @fetchRecommendedIntegrations="fetchRecommendedIntegrations"
              )
            .campaign-setting-card.card.last-child.campaign-setting-card-skeleton.no-structure-lines(
              key="add-integration-row"
            )
              .card-body.px-0.card-body-line
                .d-flex
                  om-button.text-primary(
                    secondary
                    large
                    icon="plus-circle"
                    @click="$modal.show('new-campaign-integration')"
                  ) {{ $t('addNewIntegration') }}
          template(v-else)
            .text-color-mild-gray.mb-4.font-size-0--9375.campaign-settings-box-top.px-2 {{ $t('integrationSettingLead') }}
            transition-group.row.px-2(name="fade" tag="div")
              template(
                v-if="campaign.integrations.length"
                v-for="(integration, index) in campaign.integrations"
              )
                .col-auto.campaign-integration-card.cursor-pointer.mb-4(
                  @click="$modal.show('new-campaign-integration', { integrationId: integration._id })"
                  :key="integration._id"
                )
                  integration-box(:integration="integration")
                    template(slot="action")
                      .integration-remove(
                        v-if="showIntegrationDeleteIcon(integration)"
                        @click.stop="removeRule('integrations', index)"
                      )
                        i.fas.fa-times
              analytics-box(key="analytics-box" :campaign="campaign")
              .col-auto.campaign-integration-card.cursor-pointer.d-flex.align-items-center.justify-content-center(
                key="add-new-integration"
              )
                om-button.text-primary(
                  secondary
                  large
                  icon="plus-circle"
                  @click="$modal.show('new-campaign-integration')"
                ) {{ $t('addNewIntegration') }}
      template(slot="button") {{ $t('integrationsFinish') }}
    notify-me(
      v-if="!isDynamicContent"
      :status.sync="campaign.notifyMe.status"
      :emails.sync="campaign.notifyMe.emails"
      :inputFields.sync="!campaign.noInputField"
      ref="notifyMe"
    )
    new-campaign-setting-rule(
      v-if="!isDynamicContent"
      settingType="events"
      :settingArray.sync="campaign.events"
      @editRule="editRule('events', $event)"
      :campaign="campaign"
    )
    new-campaign-integration(
      :globalIntegrations="globalIntegrations"
      :campaignIntegrations.sync="campaign.integrations"
      @refetchQuery="refetchQuery"
      :hasEmailField="campaign.hasEmailField"
      :hasPhoneField="campaign.hasPhoneField"
      :domain="campaign.domain"
    )
    shopify-remove
    domain-management(:domain.sync="campaign.domain" :domainId.sync="campaign.domainId")
    integration-remove(@refetchQuery="refetchQuery")
    portal(to="root")
      product-catalog
  integration-new-flow-modal(
    v-if="!isDynamicContent"
    :campaignIntegrations="campaign.integrations"
    :globalIntegrations="globalIntegrations"
    @fetchIntegrations="fetchIntegrations"
  )
  select-global-integration-modal(
    :globalIntegrations="globalIntegrations"
    @refetchIntegrations="refetchQuery"
  )
  SegmentsUserChooseModal(
    @updateFrontendRules="updateFrontendRules"
    :campaignSegments="campaign.segments || {}"
    :campaignVersion="campaign.version || null"
    :campaignType="campaign.templateType"
    :personalizedRules="personalizedRules"
  )
  SegmentsUserSaveModal
</template>
<script>
  import Vue from 'vue';
  import { objectHash } from '@/utils/objectHash';
  import { mapGetters, mapMutations, mapState } from 'vuex';

  import { NEW_INTEGRATION_FLOW } from '@/utils/features';
  import { getPreviewUrlStyle } from '@/util';
  import defaultCampaignSettings from '@/config/defaultCampaignSettings';

  import GET_CAMPAIGN_SETTINGS from '@/graphql/GetCampaignSettings.gql';
  import SAVE_CAMPAIGN_SETTINGS from '@/graphql/SaveCampaignSettings.gql';
  import GET_GLOBAL_INTEGRATIONS from '@/graphql/GetGlobalIntegrations.gql';
  import GET_RECOMMENDED_INTEGRATIONS from '@/graphql/GetRecommendedIntegrations.gql';

  import EventBox from '@/components/CampaignSettings/EventBox.vue';
  import FrontendRuleBox from '@/components/CampaignSettings/FrontendRuleBox.vue';
  import IntegrationBox from '@/components/CampaignSettings/IntegrationBox.vue';
  import AnalyticsBox from '@/components/CampaignSettings/AnalyticsBox.vue';
  import RecommendationBox from '@/components/CampaignSettings/RecommendedIntegrations/RecommendationBox.vue';
  import CampaignSettingsSkeleton from '@/components/CampaignSettings/Skeleton.vue';
  import NewCampaignSettingRule from '@/components/Modals/NewCampaignSettingRule.vue';
  import NewCampaignIntegration from '@/components/Modals/NewCampaignIntegration.vue';
  import ShopifyRemove from '@/components/Modals/ShopifyRemove.vue';
  import DomainManagement from '@/components/Modals/DomainManagement.vue';
  import IntegrationRemove from '@/components/Modals/IntegrationRemove.vue';
  import AnalyticsSection from '@/components/CampaignSettings/Analytics.vue';
  import CampaignSettingsBox from '@/components/CampaignSettings/SettingsBox';
  import NotifyMe from '@/components/CampaignSettings/NotifyMe';
  import AddSvg from '@/components/Svg/Icons/Add';
  import TriggerSvg from '@/components/Svg/Trigger/TriggerSvg.vue';
  import Highlight from '@/components/Highlight.vue';
  import EditableTitle from '@/components/EditableTitle.vue';
  import productFetch from '@/mixins/productFetch';
  import dynamicContent from '@/mixins/dynamicContent';
  import activeDomain from '@/mixins/activeDomain';
  import reIntegration from '@/components/ReIntegration/reIntegration';
  import ProductCatalog from '@/editor/components/modals/ProductCatalog.vue';
  import Badge from '@/components/Badge';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import IntegrationNewFlowModal from '@/components/IntegrationModals/IntegrationNewFlowModal.vue';
  import SelectGlobalIntegrationModal from '@/components/IntegrationModals/SelectGlobalIntegration.vue';
  import * as IntegrationNewFlowHelper from '@/helpers/integration/newIntegrationFlow';
  import { FLOW_STATES } from '@/helpers/integration/newIntegrationFlow';
  import Targeting from '@/components/Segments/Targeting.vue';
  import { UilTimes } from '@iconscout/vue-unicons';
  import embeddedV3 from '@/mixins/embeddedV3';
  import {
    RULE_CART_RULES_V2,
    RULE_COOKIE_SEGMENTATION,
    RULE_COUNTRY,
    RULE_CUSTOM_VARIABLES,
    RULE_NUMBER_OF_VISITED_PAGES,
  } from '@/config/frontendRules/rules';
  import { createEmbeddedUrl } from '@/utils/pncURLBuilder';

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  export default {
    components: {
      EventBox,
      FrontendRuleBox,
      IntegrationBox,
      AnalyticsBox,
      RecommendationBox,
      CampaignSettingsSkeleton,
      NewCampaignSettingRule,
      NewCampaignIntegration,
      ShopifyRemove,
      DomainManagement,
      IntegrationRemove,
      AnalyticsSection,
      CampaignSettingsBox,
      AddSvg,
      NotifyMe,
      TriggerSvg,
      Highlight,
      ProductCatalog,
      EditableTitle,
      IntegrationNewFlowModal,
      SelectGlobalIntegrationModal,
      Badge,
      Targeting,
      UilTimes,
      SegmentsUserChooseModal: () => import('@/components/Modals/Segments/UserChooseModal.vue'),
      SegmentsUserSaveModal: () => import('@/components/Modals/Segments/UserSaveModal.vue'),
    },
    mixins: [productFetch, activeDomain, embeddedV3, reIntegration, dynamicContent],

    beforeRouteLeave(to, from, next) {
      if (this.hasUnsavedChanges()) {
        this.$modal.show('dialog', {
          text: this.$t('cancelDialogTwoOpts'),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => {
                next();
                this.$modal.hide('dialog');
              },
            },
            {
              title: this.$t('cancel'),
              default: true,
              class: getBrandedClassString({ secondary: true }),
            },
          ],
        });
      } else {
        next();
      }
    },

    data() {
      return {
        recommendedIntegrations: [],
        campaignList: [],
        campaign: {
          name: '',
          device: '',
          domain: '',
          domainId: '',
          events: [],
          frontendRules: [],
          recommendedIntegrations: [],
          integrations: [],
          analyticsType: '',
          notifyMe: {
            status: false,
            emails: [],
          },
          hasEmailField: false,
          hasPhoneField: false,
          hasCoupon: false,
          noInputField: false,
        },
        globalIntegrations: [],
        activeSvgAnimation: '',
        isSaved: false,
        campaignHash: '',
        activeBox: 'trigger',
        currentCartRules: {
          rules: {},
          editedGroup: null,
          editedExpression: null,
        },
        segmentChooseModalShown: false,
      };
    },
    computed: {
      ...mapState(['account']),
      ...mapGetters([
        'brandName',
        'isShopifyDomain',
        'onboardingFinished',
        'isActiveShopifyDomain',
        'isActiveShoprenterDomain',
        'hasAccountFeature',
        'domains',
        'shouldEnableShopifyAppEmbedForDomain',
      ]),
      features() {
        return this.account && this.account.features ? this.account.features : [];
      },
      campaignId() {
        return this.$route.params.id;
      },

      targetingTitle() {
        if (this.isEmbedded) {
          return this.$t('whoToShowEmbedded');
        }
        if (this.isDynamicContent) {
          return this.$t('whoToShowCampaign');
        }
        return this.$t('whoToShowLong');
      },
      campaignType() {
        if (this.isEmbeddedV3) {
          return 'embeddedv3';
        }

        if (this.isDynamicContent) {
          return 'dynamic_content';
        }

        return this.isEmbedded ? 'embedded' : 'default';
      },
      isManuallyEmbedded() {
        return this.campaign && this.campaign.manuallyEmbedded;
      },
      hasEmbeddedPositions() {
        return (
          this.campaign &&
          ((this.campaign.positions && this.campaign.positions.length) ||
            this.campaign.manuallyEmbedded)
        );
      },
      events() {
        return this.campaign ? this.campaign.events : [];
      },
      frontendRules() {
        const _self = this;
        const fakeRules = [];

        if (this.campaign && this.campaign.hasViewedCategoryProductFilter) {
          fakeRules.push({ type: 'viewedCategoryProductFilter', readonly: true });
        }

        return this.campaign
          ? this.campaign.frontendRules
              .map((r, i) => {
                if (r.type === RULE_NUMBER_OF_VISITED_PAGES && !r.options.operator) {
                  r = {
                    type: r.type,
                    options: {
                      value: r.options.value,
                      operator: 'atLeast',
                    },
                  };
                  _self.campaign.frontendRules[i] = r;
                }

                // TODO: workaround for country selector labels
                if (r.type === RULE_COUNTRY) {
                  const countryList = require(`@/config/countries_${this.$i18n.locale}.json`);
                  const countryLabels = {};
                  countryList.forEach((country) => {
                    countryLabels[country.value] = country.label;
                  });
                  r.options.countries.forEach((country) => {
                    country.label = countryLabels[country.value];
                  });
                }

                if ([RULE_COOKIE_SEGMENTATION, RULE_CUSTOM_VARIABLES].includes(r.type)) {
                  if (!r.options.expressions) {
                    r = {
                      type: r.type,
                      options: {
                        expressions: [r.options.expression],
                      },
                    };
                  }
                  _self.campaign.frontendRules[i] = r;
                }
                return r;
              })
              .concat(fakeRules)
          : [];
      },
      previewStyle() {
        return { background: getPreviewUrlStyle(this.campaign) };
      },
      allEventsCount() {
        return Object.keys(defaultCampaignSettings.events).length;
      },
      isNew() {
        return this.newParam === 'new';
      },
      newParam() {
        return this.$route.params.new;
      },
      currentDomain() {
        return this.domains.find((d) => d.domain === this.campaign.domain);
      },
      isFirstCampaign() {
        return this.isNew && this.$route.params.id === '1';
      },
      defaultNextRouteData() {
        const name = this.isFirstCampaign ? 'user_experience_protector' : 'campaign_variants';
        const paramName = this.isFirstCampaign ? 'campaignId' : 'id';
        return { name, paramName };
      },
      redirectTo() {
        return {
          name: this.defaultNextRouteData.name,
          params: {
            [this.defaultNextRouteData.paramName]: this.$route.params.id,
            new: this.newParam,
          },
        };
      },
      isNewIntegrationFlow() {
        return this.hasAccountFeature(NEW_INTEGRATION_FLOW);
      },
      hasShopifyIntegration() {
        return (
          this.campaign.integrations.filter(
            (integration) => integration.global.type === 'shopifyCustomer',
          ).length === 1
        );
      },
      personalizedRules() {
        if (!this.campaign?.experiences) return [];
        const personalizedRules = new Set();
        this.campaign.experiences.forEach(({ frontendRules }) => {
          frontendRules.forEach(({ type }) => personalizedRules.add(type));
        });
        return Array.from(personalizedRules.values());
      },
      settingsButtonText() {
        if (this.isDynamicContent) {
          if (this.isNew) return this.$t('conversionGoals.dcFlow.toGoalSelection');
          return this.$t('integrationsFinish');
        }
        return this.$t('conditionsFinish');
      },
    },
    watch: {
      'campaign.templateType': function (v) {
        if (v === 'embedded' && this.activeBox === 'trigger') {
          this.activeBox = 'embedding';
        }
      },
      'campaign.domain': function (v) {
        this.$nextTick(() => {
          this.$bus.$emit('domainChanged', v);
        });
      },
      'campaign.integrations': function () {
        if (this.$route.query.editIntegrationId) {
          const selectedIntegration = this.campaign.integrations.find(
            (integration) => integration.id === this.$route.query.editIntegrationId,
          );

          if (this.isIntegrationNewFlowAvailable(selectedIntegration.global.type)) {
            setTimeout(() => {
              this.showIntegrationNewFlowModal(selectedIntegration);
            }, 1000);
          } else {
            setTimeout(() => {
              this.$modal.show('new-campaign-integration', {
                integrationId: selectedIntegration._id,
              });
            }, 1000);
          }

          this.$router.replace({ query: {} });
        }
      },
      activeBox(current) {
        const uri = window.location.search.substring(1);
        const URLObject = new URLSearchParams(uri);
        const oldActiveBox = URLObject.get('activeBox');

        if (oldActiveBox !== this.activeBox) {
          this.updateUrl(this.activeBox);
        }
        if (current === 'condition') this.onTargetingActivation();
      },
    },

    async created() {
      const uri = window.location.search.substring(1);
      const URLObject = new URLSearchParams(uri);
      const activeBox = URLObject.get('activeBox');

      if (activeBox !== null) {
        this.activeBox = JSON.parse(JSON.stringify(activeBox));
      } else {
        this.updateUrl(this.activeBox);
      }

      await this.fetchCampaignSettings();
      await this.fetchRecommendedIntegrations();
      if (this.hasVisitorCartRevampRule()) {
        if (this.isActiveShopifyDomain(this.campaign.domain)) {
          const productDetails = await this.getShopifyProductDetails();
          if (productDetails && productDetails.length) {
            this.mergeProductDetailsWithSavedRules(productDetails, 'shopify');
          }
        }
        if (this.isActiveShoprenterDomain(this.campaign.domain)) {
          const productDetails = await this.getShoprenterProductDetails();
          if (productDetails && productDetails.length) {
            this.mergeProductDetailsWithSavedRules(productDetails, 'shoprenter');
          }
        }
      }

      this.fetchAccountSettings();
      this.$bus.$on('saveCampaignSettings', this.saveCallback);

      if (this.$route.params?.resync) {
        this.openIntegrationModal(this.$route.params.resync);
      }
    },

    async mounted() {
      this.$bus.$on('openCatalog', this.openCatalog);
      this.$bus.$on('cancelCatalog', this.reOpenVisitorCartRules);
      this.$bus.$on('saveProductSelection', this.saveProductSelection);
      this.$bus.$on('fetchRecommendedIntegrations', async () => {
        await this.fetchCampaignSettings();
        await this.fetchRecommendedIntegrations();
      });

      this.handleIntegrationRedirect();
    },

    beforeDestroy() {
      this.$bus.$off('saveCampaignSettings', this.saveCallback);
      this.$bus.$off('openCatalog', this.openCatalog);
      this.$bus.$off('cancelCatalog', this.reOpenVisitorCartRules);
      this.$bus.$off('saveProductSelection', this.saveProductSelection);
      this.$bus.$off('fetchRecommendedIntegrations', this.fetchRecommendedIntegrations);
    },

    methods: {
      ...mapMutations(['updateEditedCampaignData', 'setCampaign']),
      openIntegrationModal(resyncParams) {
        const { integrationId, isNew, failedIntegrationResyncService } = resyncParams;
        const integrationType = failedIntegrationResyncService.getIntegrationType();

        if (isNew) {
          this.$modal.show('new-campaign-integration', {
            failedIntegrationResyncService,
          });
          return;
        }

        const { _id: campaignIntegrationId } = this.campaign.integrations.find(
          ({ id }) => id === integrationId,
        );

        if (this.isIntegrationNewFlowAvailable(integrationType)) {
          this.$modal.show('integration-new-flow', {
            integrationType,
            state: 'edit',
            campaignIntegrationId,
            failedIntegrationResyncService,
          });
        } else {
          this.$modal.show('new-campaign-integration', {
            integrationId: campaignIntegrationId,
            failedIntegrationResyncService,
          });
        }
      },
      async handleIntegrationRedirect() {
        await this.fetchAccountSettings();
        const integrationId = this.$route.query.integrationId;
        const integration = this.globalIntegrations.find((g) => g._id === integrationId);

        if (this.$route.query.newIntegrationFlow) {
          await this.handleNewFlowRedirect(integration);
          return;
        }

        if (integrationId) {
          await this.handleRedirect(integration);
        }
      },
      async handleNewFlowRedirect(integration) {
        const integrationTypeFromTheUrl = this.$route.query?.integrationType;
        if (!integration && integrationTypeFromTheUrl) {
          // handle oauth error, we re-open the integration flow modal and display error
          setTimeout(() => {
            this.$modal.show('integration-new-flow', {
              integrationType: integrationTypeFromTheUrl,
              state: FLOW_STATES.NEW,
              globalIntegrationId: null,
            });
          }, 2000);
          return;
        }

        setTimeout(() => {
          this.$modal.show('integration-new-flow', {
            integrationType: integration.type,
            state: FLOW_STATES.NEW,
            globalIntegrationId: integration._id,
          });
        }, 2000);
        this.$router.replace({ query: {} });
      },
      async handleRedirect(integration) {
        setTimeout(() => {
          this.$modal.show('new-campaign-integration', { trigger: 'loadFromUrl', integration });
        }, 1000);
        this.$router.replace({ query: {} });
      },
      noAddedIntegrationType(type) {
        const hasIntegration = this.campaign.integrations.some(
          (integration) => integration.global?.type === type,
        );
        return hasIntegration === false;
      },
      isIntegrationNewFlowAvailable(type) {
        const integrationHasNewFlow = IntegrationNewFlowHelper.isNewFlowAvailableFor(type);

        return integrationHasNewFlow && this.isNewIntegrationFlow;
      },
      showIntegrationNewFlowModal(integration) {
        this.$modal.show('integration-new-flow', {
          integrationType: integration.global.type,
          state: FLOW_STATES.EDIT,
          campaignIntegrationId: integration._id,
        });
      },
      reOpenVisitorCartRules() {
        this.$modal.show('new-frontendrules-modal', {
          ruleset: this.campaignType,
          currentPage: 2,
          ruleType: this.currentCartRules.ruleType,
          type: this.currentCartRules.isEditMode ? this.currentCartRules.ruleType : '',
          needRuleExtractionFromExperienceModal: false,
          currentCartRules: this.currentCartRules.rules,
        });
      },
      saveProductSelection(selectedProducts) {
        const currentEditedExpression =
          this.currentCartRules.rules.options.expressions?.[this.currentCartRules.editedGroup]?.[
            this.currentCartRules.editedExpression
          ] ?? this.currentCartRules.rules.options.expressions?.[this.currentCartRules.editedGroup];

        if (this.currentCartRules.ruleType) {
          if (!currentEditedExpression.meta) currentEditedExpression.meta = {};
          currentEditedExpression.meta.products = selectedProducts;

          currentEditedExpression.value = selectedProducts.map((product) => product.id || product);
        } else {
          currentEditedExpression.value = selectedProducts;
        }

        this.$modal.show('new-frontendrules-modal', {
          ruleset: this.campaignType,
          currentPage: 2,
          ruleType: this.currentCartRules.ruleType ?? RULE_CART_RULES_V2,
          type: this.currentCartRules.isEditMode
            ? this.currentCartRules.ruleType ?? RULE_CART_RULES_V2
            : '',
          needRuleExtractionFromExperienceModal: false,
          currentCartRules: this.currentCartRules.rules,
        });
      },
      openCatalog({ ruleType, currentRules, groupIndex, exprIndex, isEditMode }) {
        this.currentCartRules.rules = currentRules;
        this.currentCartRules.editedGroup = groupIndex;
        this.currentCartRules.editedExpression = exprIndex;
        this.currentCartRules.isEditMode = isEditMode;
        this.currentCartRules.ruleType = ruleType ?? null;

        const selectedProducts =
          currentRules.options.expressions?.[groupIndex]?.[exprIndex]?.value ??
          currentRules.options.expressions?.[groupIndex]?.meta?.products ??
          [];
        const setProducts = Array.isArray(selectedProducts)
          ? selectedProducts.filter(
              // Filter out "null" items
              (prod) => !!prod,
            )
          : [];

        this.$modal.hide('new-frontendrules-modal');
        this.$modal.show('product-catalog', {
          type: this.isActiveShopifyDomain(this.campaign.domain) ? 'shopify' : 'shoprenter',
          campaign: this.campaign,
          setProducts,
        });
      },
      saveCallback(param) {
        this.save(param);
      },
      cancel() {
        this.$modal.show('dialog', {
          text: this.$t('cancelDialogTwoOpts'),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => {
                this.$router.go(-1);
                this.$modal.hide('dialog');
              },
            },
            {
              title: this.$t('cancel'),
              class: getBrandedClassString({ secondary: true }),
              default: true,
            },
          ],
        });
      },

      decideDevice() {
        let mobile = false;
        let desktop = false;
        if (!this.campaign.events) return 'desktop_and_mobile';

        for (const event of this.campaign.events) {
          // if (event.type === 'exitIntent' && event.device === 'desktop_and_mobile') event.device = 'desktop'

          if (event.type === 'click') {
            for (const expr of event.options.expression) {
              if (expr.device === 'desktop_and_mobile') return 'desktop_and_mobile';
              mobile = mobile || expr.device === 'mobile';
              desktop = desktop || expr.device === 'desktop';
            }
          } else {
            if (event.device === 'desktop_and_mobile') return 'desktop_and_mobile';
            mobile = mobile || event.device === 'mobile';
            desktop = desktop || event.device === 'desktop';
          }
        }

        if (mobile && !desktop) {
          return 'mobile';
        }
        if (desktop && !mobile) {
          return 'desktop';
        }
        return 'desktop_and_mobile';
      },
      save({ redirect }) {
        this.isSaved = true;
        this.campaign.device = this.decideDevice();
        this.campaign.hasEmailField = undefined;
        this.campaign.hasPhoneField = undefined;
        this.campaign.hasCoupon = undefined;
        this.campaign.hasProductElement = undefined;

        this.campaignHash = objectHash(this.campaign);

        const pruneVisitorCartRule = (input) => {
          const pruned = _clone(input);
          const cartRulesIndex = input.frontendRules.findIndex(
            (rule) => rule.type === RULE_CART_RULES_V2,
          );
          if (cartRulesIndex > -1) {
            const prunedCartRules = input.frontendRules[cartRulesIndex].options.expressions.map(
              (group) => {
                return group.map((expression) => {
                  if (Array.isArray(expression.value)) {
                    const prunedValues = expression.value.reduce((ids, value) => {
                      let prop;
                      if (value.type === 'shoprenter') {
                        prop = 'variant';
                      } else {
                        prop = 'id';
                      }
                      if (value !== null && value[prop] !== null) {
                        ids.push(value[prop]);
                      }
                      return ids;
                    }, []);
                    return { ...expression, value: prunedValues };
                  }
                  return expression;
                });
              },
            );

            pruned.frontendRules[cartRulesIndex].options.expressions = prunedCartRules;

            return pruned;
          }
          return input;
        };

        const sendData = () => {
          let input = _clone(this.campaign);
          input = pruneVisitorCartRule(input);
          input.priority = input.priority ?? 'NORMAL';
          delete input.lastSaved;
          delete input.noInputField;
          delete input.templateType;
          delete input.hasViewedCategoryProductFilter;
          delete input.updatedAt;
          delete input.segments;
          delete input.currentExperimentId;
          delete input.currentExperimentName;
          this.$apollo
            .mutate({
              mutation: SAVE_CAMPAIGN_SETTINGS,
              variables: { input },
            })
            .then(() => {
              this.$modal.hide('dialog');
              this.$router.push(redirect);
              this.$notify({
                type: 'success',
                text: this.$t('notifications.saveSuccess'),
              });
            })
            .catch(() => {
              this.$notify({
                type: 'error',
                text: this.$t('notifications.saveError'),
              });
            });
        };

        if (!this.isEmbedded && this.campaign.events?.length === 0) {
          this.$modal.show('dialog', {
            title: '',
            text: this.$t('notifications.noTrigger'),
            buttons: [
              {
                title: this.$t('yes'),
                class: getBrandedClassString({ primary: true }, 'mr-3'),
                handler: () => {
                  sendData();
                },
              },
              {
                title: this.$t('cancel'),
                default: true,
                class: getBrandedClassString({ secondary: true }),
              },
            ],
          });
        } else {
          sendData();
        }
      },

      removeRule(type, index) {
        if (this.isNewIntegrationFlow && type === 'integrations' && this.hasShopifyIntegration) {
          const integration = this.campaign.integrations[index];
          if (integration.global.type === 'shopifyCustomer' && this.hasShopifyIntegration) {
            this.$modal.show('shopify-remove');
            return;
          }
        }

        this.$modal.show('dialog', {
          text: this.$t('confirmationDialog'),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => {
                this.campaign[type].splice(index, 1);
                this.$modal.hide('dialog');
              },
            },
            {
              title: this.$t('cancel'),
              default: true,
              class: getBrandedClassString({ secondary: true }),
            },
          ],
        });
      },

      editRule(type, rule) {
        for (let i = 0; i < this.campaign[type].length; i++) {
          if (this.campaign[type][i].type === rule.type) {
            Vue.set(this.campaign[type], i, rule);
            break;
          }
        }
      },

      async fetchCampaignSettings() {
        const {
          data: { campaign },
        } = await this.$apollo.query({
          query: GET_CAMPAIGN_SETTINGS,
          variables: {
            campaignId: this.campaignId,
          },
        });

        this.campaign = JSON.parse(JSON.stringify(campaign), (key, value) =>
          key === '__typename' ? undefined : value,
        );
        this.updateEditedCampaignData({ version: this.campaign.version });
        this.campaignHash = objectHash(this.campaign);
        this.setCampaign(this.campaign);
      },

      async fetchAccountSettings() {
        const response = await this.$apollo.query({
          query: GET_GLOBAL_INTEGRATIONS,
        });

        const globalIntegrations = response?.data?.globalIntegrations;
        for (const globalIntegration of globalIntegrations) {
          if (this.getDeprecatedIntegrations.includes(globalIntegration.type)) {
            globalIntegration.deprecated = true;
          }
        }
        this.globalIntegrations = globalIntegrations ? _clone(globalIntegrations).reverse() : [];
      },

      refetchQuery(query) {
        if (query === 'campaignSettings') {
          this.fetchCampaignSettings();
        } else if (query === 'accountSettings') {
          this.fetchAccountSettings();
        }
      },

      fetchIntegrations() {
        this.fetchAccountSettings();
      },

      showEventsModal(type) {
        this.$modal.show('new-events-modal', { type });
      },

      scrollToAnalytics() {
        this.$nextTick(() => {
          const infoElement = document.getElementById('analyticsInfo');
          if (infoElement) {
            this.$scrollTo('#analyticsInfo', 0, { offset: -100 });
          }
        });
      },

      hasUnsavedChanges() {
        return this.campaignHash !== objectHash(this.campaign);
      },

      integrationSettingValue(type, value) {
        if (Array.isArray(value) && (type === 'soundest' || type === 'omnisend'))
          return value.map((t) => t.value).join(', ');
        return value;
      },

      openWebSelector() {
        const url = createEmbeddedUrl({
          domain: this.campaign.domain,
          campaignId: parseInt(this.$route.params.id, 10),
          locale: this.$i18n.locale,
          returnUrl: this.$route.path,
          positions: this.campaign.positions,
        });
        window.open(url);
      },

      async confirmManuallyEmbedded() {
        this.$modal.hide('embed-code');
        await this.$axios.post('/web-selector/confirm-manually-embedded', {
          campaignId: this.$route.params.id,
        });
        this.campaign.manuallyEmbedded = true;
        this.campaign.positions = null;
      },

      renameCampaign(newCampaignName) {
        this.campaign.name = newCampaignName;
      },
      hasVisitorCartRevampRule() {
        return this.campaign.frontendRules.find((rule) => rule.type === RULE_CART_RULES_V2);
      },
      updateUrl(activeBox) {
        const newRoute = this.$router.resolve({
          name: 'campaign_settings',
          params: this.$route.params,
          query: {
            ...this.$route.query,
            activeBox,
          },
        });

        window.history.replaceState({}, 'om-campaign-settings', newRoute.href);
      },

      getCampaignIntegrationTypes() {
        return this.campaign.integrations.map((integration) => integration.global.type);
      },

      async fetchRecommendedIntegrations() {
        try {
          const {
            data: { recommendedIntegrations },
          } = await this.$apollo.query({
            query: GET_RECOMMENDED_INTEGRATIONS,
            variables: {
              campaignId: this.campaignId,
            },
          });
          const campaignIntegrationTypes = this.getCampaignIntegrationTypes();

          this.recommendedIntegrations = Object.keys(recommendedIntegrations).filter(
            (integration) => !campaignIntegrationTypes.includes(integration),
          );
        } catch (error) {
          console.warn('Unable to fetch recommended integrations', error);
        }
      },
      updateFrontendRules(rules) {
        this.campaign.frontendRules = rules;
        this.$modal.hide('segments-user-choose-modal');
      },
      openSegmentSaveModal() {
        this.$modal.show('segments-user-save-modal', {
          frontendRules: this.campaign.frontendRules,
        });
      },
      showIntegrationDeleteIcon(integration) {
        const integrationType = integration.global.type;
        const isShopifyCustomerIntegration = integrationType === 'shopifyCustomer';
        let isRemovableShopifyIntegration = false;

        if (isShopifyCustomerIntegration) {
          const shopifyIntegrationsWithCampaignDomain = this.campaign.integrations.filter(
            (integration) =>
              integration.global.type === 'shopifyCustomer' &&
              this.campaign.domain.includes(integration.global.data.shopifyDomain),
          );
          isRemovableShopifyIntegration =
            !this.campaign.domain.includes(integration.global.data.shopifyDomain) ||
            shopifyIntegrationsWithCampaignDomain.length > 1;
        }
        return !isShopifyCustomerIntegration || isRemovableShopifyIntegration;
      },
      openSegmentChooseModal(preselect = false) {
        this.$modal.show('segments-user-choose-modal', {
          domain: this.campaign.domain,
          preselect,
        });
      },
      onTargetingActivation() {
        if (this.isNew && !this.segmentChooseModalShown && !this.isEmbedded) {
          this.segmentChooseModalShown = true;
          this.openSegmentChooseModal(true);
        }
      },
      onTargetingClose() {
        if (this.isDynamicContent) {
          this.activeBox = 'done';
          if (this.isNew) {
            this.save({
              redirect: {
                name: 'dc_goal_create',
                params: { id: this.$route.params.id, new: this.newParam },
              },
            });
          } else {
            this.activeBox = 'done';
            this.save({ redirect: this.redirectTo });
          }
        } else {
          this.activeBox = 'integration';
        }
      },
      onIntegrationClose() {
        this.activeBox = 'done';
        this.save({ redirect: this.redirectTo });
      },
    },
  };
</script>
<style lang="sass">
  .navbar.brand-custom-navbar
    .container
      max-width: 100%!important
    .right-part
      justify-content: flex-end
</style>
