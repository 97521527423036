<template lang="pug">
div
  om-modal(
    name="integration-new-flow"
    modalClasses="integration-new-flow-modal"
    :width="800"
    color="light"
    @beforeOpen="beforeOpen"
    :scrollable="true"
  )
    template(#modal-header v-if="integrationType")
      .row
        .col
          .font-weight-bold.font-size-1--25 {{ $t(`integrations.${integrationType}.name`) }} {{ $t('integrationFlow.integration') }}
      .brand-modal-action-icon.cursor-pointer(@click="hide")
        close-icon(:width="12" :height="12" color="#AAB1C1")
    template(#modal-body)
      component(
        v-if="integrationType"
        :is="componentLoader"
        :state="state"
        :isNew="isNew"
        :isNewlyAdded="isNewlyAdded"
        :integrationData="integrationData"
        :failedIntegrationResyncService="failedIntegrationResyncService"
      )
</template>

<script>
  import { capitalizeFirstLetter } from '@/util';
  import { UilQuestionCircle } from '@iconscout/vue-unicons';
  import { FLOW_STATES } from '@/helpers/integration/newIntegrationFlow';
  import { removeErrorQueryParams } from '@/components/IntegrationModals/utils';

  export default {
    components: {
      UilQuestionCircle,
    },

    props: {
      campaignIntegrations: {
        type: Array,
        required: true,
      },
      globalIntegrations: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        integrationType: '',
        integrationData: {},
        state: FLOW_STATES.NEW,
        isNew: true,
        isNewlyAdded: false,
        globalIntegrationId: null,
        failedIntegrationResyncService: null,
      };
    },

    computed: {
      componentLoader() {
        if (!this.integrationType) {
          console.error(`Integration type is not defined: ${this.integrationType}`);
          return false;
        }

        return () => import(`./${this.integrationType}/index.vue`);
      },
    },

    mounted() {
      this.$bus.$on('form-is-valid', (isValid) => {
        this.isValid = isValid;
      });
      this.$bus.$on('fetchIntegrations', (integrationSettings) => {
        if (integrationSettings) {
          this.updateCampaignIntegrations(integrationSettings);
        }
        this.$emit('fetchIntegrations'); // fetch global integrations
      });
    },

    beforeDestroy() {
      this.$bus.$off('form-is-valid');
      this.$bus.$off('fetchIntegrations');
    },

    methods: {
      capitalizeFirstLetter,
      hide() {
        const queryParams = removeErrorQueryParams(this.$route.query);
        this.$router.replace({ queryParams });
        this.$modal.hide('integration-new-flow');
      },
      beforeOpen(event) {
        this.state = event.params.state || FLOW_STATES.NEW;

        this.integrationType = event.params.integrationType;
        this.globalIntegrationId = event.params.globalIntegrationId || null;
        this.campaignIntegrationId = event.params.campaignIntegrationId || null;

        this.integrationData = {};

        if (this.campaignIntegrationId) {
          this.integrationData = this.campaignIntegrations.find(
            (integration) => integration._id === this.campaignIntegrationId,
          );
        } else if (this.globalIntegrationId) {
          const global = this.globalIntegrations.find(
            (integration) => integration._id === this.globalIntegrationId,
          );
          this.integrationData = { global, id: global._id };
        }

        if (event.params?.failedIntegrationResyncService) {
          this.failedIntegrationResyncService = event.params.failedIntegrationResyncService;
        }
      },

      updateCampaignIntegrations(settings) {
        const integrationData = {
          _id: settings.campaignIntegrationId,
          id: settings.id,
          global: {
            data: settings.getGlobals(),
            type: settings.type,
            _id: settings.id,
          },
          ...settings.getCampaignSettings(),
        };

        const existingIntegrationIndex = this.campaignIntegrations.findIndex(
          (integration) => integration._id === integrationData._id,
        );

        if (existingIntegrationIndex !== -1) {
          this.$set(this.campaignIntegrations, existingIntegrationIndex, integrationData);
        } else {
          // eslint-disable-next-line
          this.campaignIntegrations.push(integrationData);
        }

        this.$emit('update:campaignIntegrations', this.campaignIntegrations);
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/modals/new_integration_flow.sass'

  .binding-error-message
    color: red
    font-size: 14px
</style>
